@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global base styles */
@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

/* Input Checkbox Style */
input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg);
	color: white;
	opacity: 0;
	height: 16px;
	width: 16px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
	opacity: 1;
}

/* Global styles */
body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow-x: hidden;
}

/* Sidebar container styles */
.sidebar-container {
	display: flex;
	flex-direction: column;
	width: 250px;
	/* Default sidebar width */
	transition: transform 0.3s ease, width 0.3s ease;
}

.sidebar-container.collapsed {
	width: 80px;
	/* Collapsed sidebar width */
}

/* Content transition for smooth movement with the sidebar */
.content {
	transition: margin-left 0.3s ease-in-out;
	/* Add transition to margin-left */
	margin-left: 250px;
	/* Expanded sidebar state */
}

.content.collapsed {
	margin-left: 50px;
	/* Collapsed sidebar state */
}

/* Media Queries for responsive behavior */
@media (max-width: 300px) {
	.sidebar-container {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		transform: translateX(-100%);
		z-index: 1000;
	}

	.sidebar-container.open {
		transform: translateX(0);
	}

	.content {
		margin-left: 0;
	}
}

@media (min-width: 300px) {
	.content {
		margin-left: 250px;
		/* Default expanded margin for content */
	}

	.sidebar-container.collapsed+.content {
		margin-left: 50px;
		/* Margin for collapsed sidebar */
	}
}

/* Sidebar transition for smoother behavior */
.sidebar-container {
	transition: width 0.3s ease, transform 0.3s ease;
	width: 250px;
}

.sidebar-container.collapsed {
	width: 50px;
}

/* Sidebar link transitions */
.sidebar-container .sidebar-link {
	transition: opacity 0.3s ease, padding 0.3s ease;
}

.sidebar-container.collapsed .sidebar-link-text {
	opacity: 0;
	visibility: hidden;
}

.sidebar-container.collapsed .sidebar-link {
	justify-content: center;
	padding-left: 0;
}

.sidebar-container button {
	transition: background-color 0.3s ease;
}

.sidebar-link:hover {
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 4px;
}

.sidebar-link-text {
	transition: opacity 0.1s ease;
}